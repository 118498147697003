import React from 'react'
import logo from '../images/logo.svg'
import {css} from '@emotion/core'
import styled from '@emotion/styled'

const Footer = () => (
  <footer css={{
    textAlign: 'center'
  }}>
 <img src={logo} alt="Logo" style={{width: 'auto', marginBottom: 0, margin: 'auto', height: '3.5rem'}}/>   
  </footer>
)

export default Footer