import React from 'react'
import { Link } from 'gatsby'
import logo from '../images/logo.svg'
import Twitter from '../images/twitter.svg'
import Facebook from '../images/facebook.svg'

const Header = ({ siteTitle }) => (
  <header
    style={{
      display: 'flex',
      padding: '0 8vw',
    }}
  >
  <div
    style={{
      display: 'inline-flex',
      margin: 'auto',
      marginLeft: '0',
      height: '100%',
      visibility: 'hidden',
    }}>
      <a
      href="https://www.facebook.com/scrineprod/"
      title="Facebook"
      style={{
        backgroundColor: '#',
        display: 'block',
        height: '2rem',
        width: '1.4rem',
        
        marginRight: '1rem',
      }}
      >
      <img src={Facebook} alt="Logo Facebook"/>
      </a>
      <a href="https://twitter.com/scrineprod" title="Twitter"
      style={{
        backgroundColor: '#',
        display: 'block',
        height: '2rem',
        width: '1.4rem',
      }}
      >
      <img src={Twitter} alt="Logo Twitter"/>
      </a>
    </div>
    <div
      style={{
        maxWidth: 960, margin: 'auto', textAlign: 'center'
      }}
    >
      
        <Link
          to="/"
          style={{
            margin: 'auto', display: 'flex',
          }}
        >
        <img src={logo} alt="Logo" style={{width: 'auto', marginBottom: 0, margin: 'auto', height: '3.5rem', zIndex: 9}}/>
        </Link>
    </div>
    <div
    style={{
      display: 'inline-flex',
      margin: 'auto',
      marginRight: '0',
      height: '100%',
      zIndex: 9,
    }}>
      <a
      href="https://www.facebook.com/scrineprod/"
      title="Facebook"
      style={{
        backgroundColor: '#',
        display: 'block',
        height: '1.5rem',
        width: '1.5rem',
        margin: 'auto',
        marginRight: '.8rem',
      }}
      >
      <img src={Facebook} alt="Logo Facebook"/>
      </a>
      <a href="https://twitter.com/scrineprod" title="Twitter"
      style={{
        backgroundColor: '#',
        display: 'block',
        height: '1.5rem',
        width: '1.5rem',
        margin: 'auto',
      }}
      >
      <img src={Twitter} alt="Logo Twitter"/>
      </a>
    </div>
  </header>
)

export default Header
