import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import Footer from './footer'
import '../fonts/greycliffcf-regular-webfont.woff'
import '../fonts/greycliffcf-regular-webfont.woff2'
import '../fonts/greycliffcf-regularoblique-webfont.woff'
import '../fonts/greycliffcf-regularoblique-webfont.woff2'
import '../fonts/greycliffcf-extraboldoblique-webfont.woff2'
import '../fonts/greycliffcf-extraboldoblique-webfont.woff'
import './layout.css'


const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Scrine produit des contenus vidéos, des séries documentaires et des films documentaires' },
            { name: 'keywords', content: 'vidéos, plateforme, millenials, documentaire, séries' },
  {name: 'google-site-verification', content: 'HrpXZD-OYLuWK9b-kkPdwSJ4P9TzE_35F7xsLV1gM9k'}
          ]}
        >
          <html lang="fr_FR" />
        </Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        
        
        
          {children}
         
       
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
